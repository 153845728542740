import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src362702145/src/www.shotwire.com/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Products" description="Shotwire Products, Services & Apps for Photos and Videos." mdxType="SEO" />
    <h1 {...{
      "id": "products-services-apps"
    }}>{`Products, Services, Apps`}</h1>
    <p>{`Shotwire offers tailored products for media content producers, contributors, and consumers.`}</p>
    <p>{`Please select a product or service that best fits your use case requirements.
Contact us if you have any questions. We can help tailor the best solution for your needs with one or more of our products. We can also integrate legacy or third party requirements as needed.`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:sales@shotwire.xyz",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`sales@shotwire.xyz`}</a>{`
+1(424)229-2118`}</p>
    <h2 {...{
      "id": "choose-your-solution"
    }}>{`Choose your solution`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "512px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC20lEQVQ4y61USU+UQRB9LBqvihfFRBlAUDFqOOjVg8tRoizihhAPmhjBwYlsHyKCC6CREWM+ZJkYktFogETxYEAkkih4UPAgi/yFqotnfV9/PcNcCDHayct7VV316I+uHsBbc2IIv8lLshmLGsBPyVgZmoFFCWBJ0/FLk0zvgvoemJIkfNVUo3/IIczLFDc7sCCPyV0rgHvSgXmdwJwWmt5vusZ4xdeMOJjVAaKPugYz2kg4Jm+gTkLs7dVgVlx8lyj13WWjKcnDtDTji0xgWmupQ+RGskNuIByrbRzXrFGvtt72NmNK84FJKcek9uOjHrb6HHGS+gxRRn2WKE2IT9vYy5eZvOnVJ0QQGNVTGNNrGOdljOkeYgeRhTHJJe9mPpMcwKjswnvNYz6HcTZ5J9lDLsaVvXKJNZeBt1qBESnASmtI0/BOsOoa0YP0qgYG+ZlDUmKTqfjAMfDwmrfmG97CIG/zJU/orWFNxrAk4xNrxok34k/IkBxlHT85quVEsUlGWRxbUU3xmTf+Qs/juT7CgFTG9/vsiMR6onKEmoYRGkasYSTBMCIpNuegV/YaPSAX0S/P8FTXm7iXJ4z1RGgY8QxdGrrW0E0wdK2hyzHpkXyju3iz3fKKp9to4h4axnpcGrqeYZiGYWsYTjAM208O63U81CJ06n0a1sf3u+0nx3rCNAx7hu00bFf/Ulr5D65joYfbutbk2vQO9z/jgew3cT1P1c7TXyXXEa32Utr0KOto2CIVaNGVx6ZNN+Gerlt1bFo5Ni3e2DicfEcrUS8b+Ndz0aCZaJCtRIA6B7Wyhfl01mxnTTbzGcQ25rIYZ1FnktNYewGON9ghzmGIjzzE5xOSUnIhcYz6BFFCXUQUUHt5Lz7uxyZf7NeZ3k4yT1gl+1Ap7ajUJvJNMsEfC183+TEfvs8J+/HYq71B7kCVHsD/X0HeaJBPKagx/kvY3mrLuCIW+g9Y7v8DRhounk1uKEUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/47551bced584a8d2c8680d6904fb27e3/d3be9/video.webp 480w", "/static/47551bced584a8d2c8680d6904fb27e3/bd5dd/video.webp 512w"],
            "sizes": "(max-width: 512px) 100vw, 512px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/47551bced584a8d2c8680d6904fb27e3/e85cb/video.png 480w", "/static/47551bced584a8d2c8680d6904fb27e3/01e7c/video.png 512w"],
            "sizes": "(max-width: 512px) 100vw, 512px",
            "type": "image/png"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/47551bced584a8d2c8680d6904fb27e3/01e7c/video.png",
            "alt": "Video Solutions",
            "title": "Video Solutions",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "shotwire-video-cloud---beta"
    }}>{`Shotwire Video Cloud - Beta`}</h2>
    <p><a parentName="p" {...{
        "href": "https://video.shotwire.xyz/Cloud",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`learn more here`}</a></p>
    <ul>
      <li parentName="ul">{`Video Asset Management Cloud Services  `}</li>
      <li parentName="ul">{`Custom Encoding Workflows for various playback proxy videos`}</li>
      <li parentName="ul">{`Public & Private Marketplace and Distribution tools`}</li>
    </ul>
    <h2 {...{
      "id": "shotwire-enterprise"
    }}>{`Shotwire Enterprise`}</h2>
    <p><a parentName="p" {...{
        "href": "https://video.shotwire.xyz/Enterprise",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`learn more here`}</a></p>
    <ul>
      <li parentName="ul">{`Shotwire Licensed Private platform   `}</li>
      <li parentName="ul">{`Service Level Agreements available`}</li>
      <li parentName="ul">{`Complete Source Control with Full CI/CD Workflow Integrations`}</li>
    </ul>
    <h2 {...{
      "id": "shotwire-video-content-subscription-licenses"
    }}>{`Shotwire Video Content Subscription Licenses`}</h2>
    <p><a parentName="p" {...{
        "href": "https://video.shotwire.xyz/VideoSubscriptions",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`learn more here`}</a></p>
    <ul>
      <li parentName="ul">{`Shotwire Video Content Use Licenses available from our in house archive as well as partner providers and contributors   `}</li>
      <li parentName="ul">{`Individual Content licenses also available `}<a parentName="li" {...{
          "href": "https://video.shotwire.xyz/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`here`}</a></li>
      <li parentName="ul">{`Search for the content you need or get alerts emailed to you when content arrives in our marketplace`}</li>
    </ul>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/Cloud",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE CLOUD SERVICES`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/Enterprise",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE ENTERPRISE SERVICES`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/VideoSubscriptions",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE VIDEO CONTENT LICENSE SUBSCRIPTIONS`}</a></p>
      </li>
    </ol>
    <h2 {...{
      "id": "use-cases"
    }}>{`Use cases`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Case 1:`}</strong>{` Professional photographer uploads photos and videos to Shotwire Cloud to securely distribute photos and videos to clients around the globe and provide a private marketplace for authenticated users to purchases licenses.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Case 2:`}</strong>{` International Photo Agency editors work around the clock editing photos uploaded by event photographers on location in real time and blasting final cuts to news clients across the globe.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Case 3:`}</strong>{` Photo-journalist on assignment uploads time sensitive photos and privately streams files to client.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Case 4:`}</strong>{` News producers search Shotwire’s content and partner archives to locate desired content for publishing in print, television, and internet productions. Live alerts on desired content feeds are available for breaking news content.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      